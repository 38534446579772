/* import __COLOCATED_TEMPLATE__ from './logs.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import EmberObject, { computed } from '@ember/object';
import { A } from '@ember/array';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import BaseContentComponent from 'embercom/components/settings/content-components/base';
import { ADMIN_EVENT_TYPES } from 'embercom/lib/admins/app-admin-events-constants';
import ExportHelper from 'embercom/lib/reporting/export-helper';
import { DATE_WITHOUT_TIME, CSV_EMPTY_LINE } from 'embercom/lib/reporting/export-helper';
import Range from 'embercom/models/reporting/range';
import { getOwner } from '@ember/application';
import { task } from 'ember-concurrency';
import AppAdminEventsHelper from 'embercom/lib/admins/app-admin-events-helper';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import moment from 'moment-timezone';

export default BaseContentComponent.extend({
  intercomEventService: service(),
  intl: service(),
  appService: service(),
  csv: service(),
  app: alias('appService.app'),
  admins: alias('app.admins'),
  classNames: ['flex-grow-1', 'flex-shrink-1', 'flex', 'flex-col'],
  exportHelper: null,
  eventsHelper: null,
  filterByAdmin: null,
  filterByEventType: 'AppAdminEvents::All',

  get shouldShowExportButton() {
    return this.rows.length > 0;
  },

  init() {
    this._super(...arguments);
    this.exportHelper = new ExportHelper(getOwner(this));
    this.eventsHelper = new AppAdminEventsHelper(getOwner(this));
    this.maxPastDate = moment().subtract(1, 'year').add(1, 'day');
    this.set('columns', [
      EmberObject.create({
        label: this.intl.t('settings.content-components.logs.activity'),
        canExpandToFit: true,
        component: 'table-cells/admin-events/description-cell',
      }),
      EmberObject.create({
        label: this.intl.t('settings.content-components.logs.date'),
        canExpandToFit: true,
        component: 'table-cells/admin-events/date-cell',
      }),
      EmberObject.create({
        label: this.intl.t('settings.content-components.logs.ip-address'),
        canExpandToFit: true,
        propertyName: 'eventAdminIp',
      }),
    ]);
  },

  columns: A(),

  rows: computed('filteredEvents.[]', function () {
    return this.filteredEvents.map((event) => this.buildRow(event)).compact();
  }),

  buildRow(event) {
    let eventType = ADMIN_EVENT_TYPES.findBy('value', event.get('type'));
    if (!eventType) {
      return;
    }
    return EmberObject.create({
      event,
      eventAdminIp: event.get('admin_ip') || '',
    });
  },

  buildEventType(eventType) {
    return {
      text: this.intl.t(eventType.text),
      value: eventType.value,
      isSelected: this.filterByEventType === eventType.value,
    };
  },

  matchesAdmin(event) {
    let filterByAdminId = this.get('filterByAdmin.id');
    return filterByAdminId ? event.get('admin.id') === filterByAdminId : true;
  },

  matchesEventType(event) {
    let filterByEventType = this.filterByEventType;
    let shouldFilterEvent = !!filterByEventType && filterByEventType !== 'AppAdminEvents::All';
    return shouldFilterEvent ? event.get('type') === filterByEventType : true;
  },

  filterImpersonatedEvents(event) {
    return event.type === 'AppAdminEvents::AdminImpersonationStart' || !event.impersonation_id;
  },

  filteredEvents: computed(
    'displayedEvents.[]',
    'filterByAdmin',
    'filterByEventType',
    'filterImpersonatedEvents',
    function () {
      let displayedEvents = this.displayedEvents;
      let filterByEventType = this.filterByEventType;
      let filterByAdmin = this.filterByAdmin;

      displayedEvents = displayedEvents.filter(this.filterImpersonatedEvents);
      if ((!filterByEventType || filterByEventType === 'AppAdminEvents::All') && !filterByAdmin) {
        return displayedEvents;
      }

      return displayedEvents.filter((event) => {
        return this.matchesEventType(event) && this.matchesAdmin(event);
      });
    },
  ),

  adminEventTypes: computed('filterByEventType', function () {
    let options = ADMIN_EVENT_TYPES.map((type) => this.buildEventType(type));
    return [
      {
        hasItemsMarkableAsSelected: true,
        items: options.sortBy('text'),
      },
    ];
  }),

  csvTableData() {
    return this.filteredEvents.map((event) => {
      let description = htmlToTextContent(this.eventsHelper.descriptionHTML(event));
      let date = this.intl.formatDate(event.created_at, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: this.app.timezone,
        timeZoneName: 'short',
      });
      return [description, date, event.admin_ip];
    });
  },

  csvFilters() {
    return [
      [this.intl.t('settings.content-components.logs.filters')],
      [
        this.intl.t('settings.content-components.logs.teammate'),
        this.intl.t('settings.content-components.logs.activity'),
      ],
      [
        this.filterByAdmin?.name || this.intl.t('settings.content-components.logs.all'),
        this.intl.t(ADMIN_EVENT_TYPES.findBy('value', this.filterByEventType).text),
      ],
    ];
  },

  headerRow() {
    return [this.columns.map((column) => column.label)];
  },

  exportCsvTask: task(function* () {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'download_csv_button',
      place: 'teammate_activity_logs',
    });
    let range = Range.createFromParams(this.fromMoment, this.toMoment, this.app.timezone);
    let filename = `teammate_activity_logs_${this.exportHelper.dateFormatter(
      range.startMoment,
      DATE_WITHOUT_TIME,
    )}_${this.exportHelper.dateFormatter(range.endMoment, DATE_WITHOUT_TIME)}.csv`;

    let title = [[this.intl.t('apps.app.settings.logs.title-token')]];
    let data = title
      .concat(CSV_EMPTY_LINE)
      .concat(this.exportHelper.csvHeaders(range))
      .concat(CSV_EMPTY_LINE)
      .concat(this.csvFilters())
      .concat(CSV_EMPTY_LINE)
      .concat(this.headerRow())
      .concat(this.csvTableData());
    return yield this.csv.export(data, {
      fileName: filename,
      withSeparator: false,
    });
  }).drop(),

  actions: {
    load() {
      this.load();
    },
    setFilterByAdmin(adminId) {
      let admin = this.admins.findBy('id', adminId);
      this.set('filterByAdmin', admin);
      if (admin) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'filtered_by_admin',
          object: admin,
          models: [admin],
        });
      }
    },
    setFilterByEventType(eventType) {
      this.set('filterByEventType', eventType);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'filtered_by_event',
        object: eventType,
      });
    },
    updateDate(dateType) {
      this.send('load');
      this.intercomEventService.trackAnalyticsEvent({
        action: 'filtered_by_date',
        object: dateType,
      });
    },
  },
});
