/* import __COLOCATED_TEMPLATE__ from './chart-actions-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Chart from 'embercom/models/reporting/custom/chart';
import type Report from 'embercom/models/reporting/custom/report';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import type ReportingCsvExport from 'embercom/services/reporting-csv-export';
import type IntlService from 'ember-intl/services/intl';
import { ActionType } from 'embercom/components/reporting/custom/chart-action-options';
interface Args {
  seeSourceData: () => void;
  chart: Chart;
  report: Report | null;
  reportState?: { isStatic?: boolean };
  renderableChart: RenderableChart;
  openUnderlyingDataModal: () => void;
  disabled?: boolean;
  closeActionDropdown: () => void;
  openActionDropdown: () => void;
  editChart?: (chartId: string) => void;
  duplicateChart?: (chartId: string, description: string) => void;
  deleteChart?: (chartId: string) => void;
  editMode?: boolean;
  isPaywalledCustomChart?: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: {
    default: [{ dropdownItems: Record<string, any>[] }];
  };
}
export default class ChartActionsDropdown extends Component<Signature> {
  @service declare appService: $TSFixMe;

  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare reportingCsvExport: ReportingCsvExport;

  get canSeeLegacyExplorationActions() {
    return !this.args.reportState?.isStatic;
  }

  get canExportAggregatedData() {
    return !this.args.chart.isCounter && !this.args.chart.isBespoke;
  }

  get dataExplorationOptions() {
    let chart = this.args.chart;
    let options: any[] = [
      {
        name: this.intl.t('reporting.custom-reports.chart.see-underlying-data'),
        icon: 'attribute-titles',
        onClick: this.args.openUnderlyingDataModal,
        actionType: ActionType.DRILL_IN,
        supportsMultimetric: true,
      },
    ];

    if (this.canSeeLegacyExplorationActions) {
      if (chart.chartSeries.firstObject.metric.model === 'ticket') {
        options.push({
          name: this.intl.t('reporting.custom-reports.chart.see-tickets'),
          icon: 'ticket',
          onClick: this.args.seeSourceData,
          supportsMultimetric: false,
        });
      }

      if (chart.chartSeries.firstObject.metric.model === 'conversation') {
        options.push({
          name: this.intl.t('reporting.custom-reports.chart.see-conversations'),
          icon: 'conversation',
          onClick: this.args.seeSourceData,
          supportsMultimetric: false,
        });

        options.push({
          name: this.intl.t(
            'reporting.custom-reports.chart.explore-data.actions.export-conversations',
          ),
          icon: 'conversation',
          onClick: this.downloadSourceDataAsCsv,
          state: 'legacy',
          tooltipText: this.intl.t('reporting.custom-reports.chart.export-modal.legacy-tooltip'),
          supportsMultimetric: false,
        });
      }
    }

    if (this.canExportAggregatedData) {
      options.push({
        name: this.intl.t('reporting.custom-reports.chart.explore-data.actions.export-chart-data'),
        icon: 'download',
        onClick: this.exportAggregatedDataAsCsv,
        supportsMultimetric: true,
      });
    }

    if (chart.isMultimetric) {
      options = options.filter((option) => option.supportsMultimetric);
    }

    return options;
  }

  get chartEditOptions() {
    return [
      {
        name: this.intl.t('components.reporting.custom.report.draggable-chart-chrome.actions.edit'),
        icon: 'edit',
        actionType: ActionType.REPORT_ACTION,
        onClick: this.args.editChart,
      },
      {
        name: this.intl.t(
          'components.reporting.custom.report.draggable-chart-chrome.duplicate-chart',
        ),
        icon: 'duplicate',
        actionType: ActionType.DUPLICATE_CHART,
        onClick: this.args.duplicateChart,
      },
      {
        name: this.intl.t(
          'components.reporting.custom.report.draggable-chart-chrome.actions.delete',
        ),
        icon: 'trash',
        actionType: ActionType.REPORT_ACTION,
        onClick: this.args.deleteChart,
        classList: 'text-red',
      },
    ];
  }

  get groupList(): { heading?: string; items: any[] }[] {
    let dataExplorationOptions = this.dataExplorationOptions;
    let chartEditOptions = this.chartEditOptions;
    let explorationHeader = this.intl.t(
      'components.reporting.custom.report.draggable-chart-chrome.data-exploration',
    );
    let editHeader = this.intl.t(
      'components.reporting.custom.report.draggable-chart-chrome.edit-options',
    );

    if (!this.args.report) {
      return [{ items: dataExplorationOptions }];
    }

    if (this.args.isPaywalledCustomChart) {
      return [{ heading: editHeader, items: [chartEditOptions.lastObject] }];
    }

    if (!this.args.editMode) {
      return [{ heading: explorationHeader, items: dataExplorationOptions }];
    }

    return [
      { heading: explorationHeader, items: dataExplorationOptions },
      { heading: editHeader, items: chartEditOptions },
    ];
  }

  @action
  async downloadSourceDataAsCsv() {
    this.reportingCsvExport.exportChartData(this.args.chart, this.args.report);
  }

  @action
  async exportAggregatedDataAsCsv() {
    this.reportingCsvExport.exportChartDataPoints(
      this.args.renderableChart,
      this.args.chart,
      this.args.report,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartActionsDropdown': typeof ChartActionsDropdown;
    'reporting/custom/chart-actions-dropdown': typeof ChartActionsDropdown;
  }
}
