/* import __COLOCATED_TEMPLATE__ from './static-report-chart-layout.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import type Report from 'embercom/models/reporting/custom/report';
import type Chart from 'embercom/models/reporting/custom/chart';
import { HEIGHT_TYPES, WIDTH_TYPES } from 'embercom/models/reporting/custom/chart';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type { AnalyticsData } from 'embercom/components/reporting/custom/report/chart-card';

interface Signature {
  Args: {
    charts: Chart[];
    report: Report;
    reportState: any;
  };
  Blocks: {
    default: any;
  };
}

const widthTypeToCssClassMapping = {
  [WIDTH_TYPES.FULL]: 'col-span-12',
  [WIDTH_TYPES.TWO_COLUMN]: 'col-span-4',
  [WIDTH_TYPES.THREE_COLUMN]: 'col-span-6',
  [WIDTH_TYPES.FOUR_COLUMN]: 'col-span-8',
  [WIDTH_TYPES.ONE_QUARTER]: 'col-span-3',
};

const heightTypeToCssClassMapping = {
  [HEIGHT_TYPES.X_SMALL]: 'row-span-2',
  [HEIGHT_TYPES.SMALL]: 'row-span-3',
  [HEIGHT_TYPES.TALL]: 'row-span-5',
  [HEIGHT_TYPES.X_TALL]: 'row-span-7',
};

export default class StaticReportChartLayout extends Component<Signature> {
  @service intercomEventService: $TSFixMe;
  widthClass(chart: Chart) {
    return widthTypeToCssClassMapping[chart.widthType];
  }

  heightClass(chart: Chart) {
    return heightTypeToCssClassMapping[chart.heightType || HEIGHT_TYPES.TALL];
  }

  @action
  trackAnalyticsEvent(options: AnalyticsData) {
    this.intercomEventService.trackAnalyticsEvent({
      ...options,
      report_id: this.args.report.id,
    });
  }

  noop() {}
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::InContext::StaticReportChartLayout': typeof StaticReportChartLayout;
    'reporting/in-context/static-report-chart-layout': typeof StaticReportChartLayout;
  }
}
