/* import __COLOCATED_TEMPLATE__ from './sla-table.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  slas: any[];
  officeHoursSchedules: any[];
}

export default class SlaTable extends Component<Args> {
  @service declare appService: $TSFixMe;

  @tracked currentTab = 'live';
  @tracked showArchiveModal = false;
  @tracked showDeleteModal = false;
  @tracked slaToArchive: any = null;
  @tracked slaToDelete: any = null;
  @tracked slaInputToShow = -1;
  @tracked slaName = '';

  get app() {
    return this.appService.app;
  }

  get inboxRulesLink() {
    return 'apps.app.settings.inbox-rules.list';
  }

  get liveTabActive() {
    return this.currentTab === 'live';
  }

  get slaList() {
    return this.currentTab === 'live' ? this.liveSlaList : this.archivedSlaList;
  }

  get liveSlaList() {
    return this.rows.rejectBy('archived');
  }

  get archivedSlaList() {
    return this.rows.filterBy('archived');
  }

  get rows() {
    return this.slasWithOfficeHours;
  }

  get hasSlas() {
    return this.slasWithOfficeHours.length > 0;
  }

  get hasArchivedSlas() {
    return this.archivedSlaList.length > 0;
  }

  get nameExists() {
    return (
      this.rows
        .filter((sla) => sla.id !== this.slaInputToShow)
        .filterBy('name', this.slaName.trim()).length > 0
    );
  }

  get slasWithOfficeHours() {
    return this.args.slas.map((sla) => {
      if (sla.officeHoursSchedule === undefined) {
        sla.officeHoursSchedule = this.args.officeHoursSchedules.findBy(
          'id',
          sla.officeHoursScheduleId,
        );
      }

      return sla;
    });
  }

  get columns() {
    let columns = [];

    if (!this.app.canUseHelpdeskSetupWip) {
      columns.push({
        label: 'Name',
        valuePath: 'name',
        isSortable: true,
        sortedAscending: true,
        width: '60%',
      });
    } else {
      columns.push({
        label: 'Name',
        valuePath: 'name',
        isSortable: true,
        sortedAscending: true,
        width: '20%',
      });
      columns.push({
        label: 'Conversations',
        valuePath: 'conversations',
        isSortable: false,
      });
      columns.push({
        label: 'Tickets',
        valuePath: 'tickets',
        isSortable: false,
      });
      columns.push({
        label: 'SLA time adjustment',
        valuePath: 'sla_time_adjustment',
        isSortable: false,
      });
      columns.push({
        label: 'Office Hours',
        valuePath: 'office_hours',
        isSortable: false,
      });
    }

    let dateLabel;
    let datePath;

    if (this.liveTabActive) {
      dateLabel = 'Created';
      datePath = 'createdAt';
    } else {
      dateLabel = 'Archived';
      datePath = 'archivedAt';
    }

    columns.push({
      label: dateLabel,
      valuePath: datePath,
      isSortable: true,
      sortedAscending: false,
    });

    return columns;
  }

  @action
  changeTab(tab: string) {
    this.currentTab = tab;
  }

  @action
  confirmArchiveSla(sla: any) {
    this.showArchiveModal = true;
    this.slaToArchive = sla;
  }

  @action
  confirmDeleteSla(sla: any) {
    this.showDeleteModal = true;
    this.slaToDelete = sla;
  }

  @action
  closeArchiveModal() {
    this.showArchiveModal = false;
    this.slaToArchive = null;
  }

  @action
  closeDeleteModal() {
    this.showDeleteModal = false;
    this.slaToDelete = null;
  }

  @action
  showSlaInputFor(sla: any) {
    this.slaInputToShow = sla.id;
    this.slaName = sla.name;
  }

  @task({ drop: true })
  *archiveSla() {
    let sla = this.slaToArchive;
    yield taskFor(this.toggleArchiveState).perform(sla, true);
    this.closeArchiveModal();
  }

  @task({ drop: true })
  *restoreSla(sla: any) {
    yield taskFor(this.toggleArchiveState).perform(sla, false);
    if (this.hasArchivedSlas === false) {
      this.changeTab('live');
    }
  }

  @task({ drop: true })
  *toggleArchiveState(sla: any, newState: boolean) {
    sla.archived = newState;
    yield sla.save();
  }

  @task({ drop: true })
  *deleteSla() {
    let sla = this.slaToDelete;
    yield sla.destroyRecord();
    this.closeDeleteModal();
    if (this.hasArchivedSlas === false) {
      this.changeTab('live');
    }
  }

  @task({ drop: true })
  *changeSlaName(sla: any, name: string) {
    sla.name = name.trim();
    yield sla.save();
    this.slaInputToShow = -1;
    this.slaName = '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Data::SlaTable': typeof SlaTable;
  }
}
