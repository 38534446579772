/* import __COLOCATED_TEMPLATE__ from './share-button.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import ReportingCustomReportShare from 'embercom/components/reporting/custom/report/share';
import type ReportAccessService from 'embercom/services/report-access-service';
import type ReportAccessControlList from 'embercom/models/reporting/report-access-control-list';
import type Report from 'embercom/models/reporting/custom/report';

interface Args {
  disabled: boolean;
  report: Report;
}

export default class ReportingCustomReportShareButton extends Component<Args> {
  @service declare modalService: $TSFixMe;
  @service declare reportAccessService: ReportAccessService;

  get hasWorkspaceAccess(): ReportAccessControlList | undefined {
    return this.reportAccessService.reportAccess.find((control) => control.adminId === null);
  }
  get shareIcon() {
    return this.hasWorkspaceAccess ? 'multiple-people' : 'locked';
  }

  @action
  handleModalOpen() {
    this.modalService.openModal(ReportingCustomReportShare, { report: this.args.report });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ShareButton': typeof ReportingCustomReportShareButton;
    'reporting/custom/report/share-button': typeof ReportingCustomReportShareButton;
  }
}
