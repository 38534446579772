/* import __COLOCATED_TEMPLATE__ from './add-chart-template-side-panel-card.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type Report from 'embercom/models/reporting/custom/report';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type { ChartTemplate } from 'embercom/services/reporting-templates';
import type IntlService from 'ember-intl/services/intl';
import { type Aggregation } from 'embercom/objects/reporting/unified/metrics/types';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type ReportingTemplates from 'embercom/services/reporting-templates';
import { modifier } from 'ember-modifier';
import type Paywall from 'embercom/components/paywall';

interface Args {
  chartTemplate: ChartTemplate;
  idx: number;
  report: Report;
  reportTemplate: any;
}

export default class AddChartTemplateSidePanelCard extends Component<Args> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare reportingTemplates: ReportingTemplates;
  @service declare appService: any;
  @tracked addedToReport = false;
  @tracked selectedAggregation: Aggregation = 'median';
  @tracked isHovered = false;
  @tracked isDragging = false;
  @tracked paywall: Paywall | null = null;

  updatePaywall = modifier((_: Element, [paywall]: [Paywall]) => {
    this.paywall = paywall;
  });

  get paywallIsActiveAsString() {
    return this.paywall?.isActive.toString() ?? 'false';
  }

  @action
  addChart() {
    if (this.paywall?.isActive) {
      return;
    }

    this.args.report.addChartFromTemplate(this.chartDefinition);
    this.addedToReport = true;
  }

  get chartDefinition() {
    return this.reportingTemplates.buildChartFromTemplate(
      this.args.chartTemplate,
      this.selectedAggregation,
      this.args.report,
    );
  }

  get aggregations() {
    return [
      { text: this.intl.t('reporting.aggregations.median'), value: 'median' },
      { text: this.intl.t('reporting.aggregations.mean'), value: 'mean' },
    ];
  }

  @action
  updateAggregation(aggregation: Aggregation) {
    this.selectedAggregation = aggregation;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::AddChartTemplateSidePanelCard': typeof AddChartTemplateSidePanelCard;
    'reporting/custom/report/add-chart-template-side-panel-card': typeof AddChartTemplateSidePanelCard;
  }
}
