/* import __COLOCATED_TEMPLATE__ from './view-mode-options.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { type TaskGenerator } from 'ember-concurrency';
import type Report from 'embercom/models/reporting/custom/report';
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  report: Report;
  duplicateReport: () => TaskGenerator<void>;
  deleteReport: () => TaskGenerator<void>;
  onClickEditReport: () => void;
  editReportDisabled: boolean;
}

const ReportingCustomReportViewModeOptions = templateOnlyComponent<Args>();
export default ReportingCustomReportViewModeOptions;
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ViewModeOptions': typeof ReportingCustomReportViewModeOptions;
    'reporting/custom/report/view-mode-options': typeof ReportingCustomReportViewModeOptions;
  }
}
