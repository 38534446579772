/* import __COLOCATED_TEMPLATE__ from './external-link-shield.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Session from 'embercom/services/session';

interface Args {
  linkURL: string;
  linkDomain: string;
  malicious: boolean;
  closeTab: () => void;
}

export default class ExternalLinkShield extends Component<Args> {
  @service declare permissionsService: any;
  @service declare session: Session;
  @service declare intercomEventService: any;

  get isMaliciousLink() {
    return this.session.workspace?.maliciousLinkBlockingEnabled && this.args.malicious;
  }

  @action
  handleClick(event: PointerEvent) {
    let permission = 'can_access_workspace_settings';
    if (!this.permissionsService.currentAdminCan(permission)) {
      event.preventDefault();
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(permission);
    }
  }

  @action
  trackGoBackEvent() {
    this.intercomEventService.trackAnalyticsEvent(
      {
        action: 'clicked',
        object: 'go_back_interstitial_button',
        admin_id: this.session.teammate.id,
        link_href: this.args.linkURL,
      },
      true,
    );
    this.args.closeTab();
  }

  @action
  trackOpenUnsafeURLEvent(event: PointerEvent) {
    // prevent immediate redirection so that we can fire an analytics event
    event.preventDefault();
    this.intercomEventService.trackAnalyticsEvent(
      {
        action: 'clicked',
        object: 'open_untrusted_url_interstitial_button',
        admin_id: this.session.teammate.id,
        link_href: this.args.linkURL,
      },
      true,
    );
    window.location.assign(this.args.linkURL);
  }

  @action
  trackOpenMaliciousURLEvent(event: PointerEvent) {
    // prevent immediate redirection so that we can fire an analytics event
    event.preventDefault();
    this.intercomEventService.trackAnalyticsEvent(
      {
        action: 'clicked',
        object: 'open_malicious_url_interstitial_button',
        admin_id: this.session.teammate.id,
        link_href: this.args.linkURL,
      },
      true,
    );
    window.location.assign(this.args.linkURL);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Security::ExternalLinkShield': typeof ExternalLinkShield;
    'settings/security/external-link-shield': typeof ExternalLinkShield;
  }
}
