/* import __COLOCATED_TEMPLATE__ from './report.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { isEmpty, flatten } from 'underscore';
import { tracked } from '@glimmer/tracking';
import { filterableProperties as defaultFilterableProperties } from '../../../lib/reporting/custom/filter-helpers';
import { isPresent } from '@ember/utils';
import CONFIRMATION_MODAL_CONTENT from 'embercom/lib/reporting/custom/navigation-guard';
import { registerDestructor } from '@ember/destroyable';
import { captureException } from '@sentry/browser';

const OVERWRITE_WARNING_KEY = 'custom_report_filter_overwrite_warning';

export default class Report extends Component {
  @service store;
  @service appService;
  @service intercomConfirmService;
  @service intercomEventService;
  @service notificationsService;
  @service router;
  @service permissionsService;
  @service reportSubscriptionService;
  @service favoritesService;
  @service customReportsService;
  @service reportingMetrics;
  @service reportingService;
  @service navbarCollapsingService;
  @service intl;
  @service reportAccessService;

  @tracked dateRange;
  @tracked showSubscriptionModal = false;
  @tracked timezoneSelected = null;
  timezoneList = this.customReportsService.timezones();

  constructor() {
    super(...arguments);
    this.timezoneSelected = this.defaultTimezoneName;
    if (this.appService.app.canSeeR2Beta && this.args.report.isNew) {
      window.onbeforeunload = (event) => {
        event.preventDefault();
        // Included for legacy support, e.g. Chrome/Edge < 119
        event.returnValue = this.intl.t('reporting.custom-reports.report.unload-warning');
        this.intercomEventService.trackAnalyticsEvent({
          action: 'reload',
          property: 'page',
          object: 'custom_report_2',
          section: 'reports',
        });
        return event.returnValue;
      };

      registerDestructor(this, () => {
        window.onbeforeunload = undefined;
      });
    }
  }

  get reportState() {
    return {
      settings: this.args.settings,
      dateRange: this.args.report.range,
      filters: this.args.report.filters,
      timezone: this.args.report.reportTimezone,
    };
  }

  get conversationAttributeDescriptors() {
    return this.store.peekAll('conversation-attributes/descriptor');
  }

  get filterableProperties() {
    return defaultFilterableProperties().concat('teammate', 'team');
  }

  get filterableMetricProperties() {
    return flatten([
      this.reportingMetrics.getPropertiesByIds(this.filterableProperties),
      this.reportingMetrics.getFilterableDatasetCdaAttributesFor('conversation'),
    ]);
  }

  get filtersWithDateRange() {
    return this.args.report.filters;
  }

  get hasFilters() {
    return isPresent(this.filtersWithDateRange);
  }

  get haveFiltersChanged() {
    return this.args.report.haveFiltersChanged;
  }

  @action
  resetFiltersInViewMode() {
    this.args.report.rollbackAttributes();
    this.args.report.reload();
  }

  get isAddFilterDisabled() {
    return this.args.report.charts.length === 0 || this.restrictedViewAccess;
  }

  get storageKey() {
    return `${OVERWRITE_WARNING_KEY}_${this.args.report.id}`;
  }

  get shouldDisplayDateFilterWarning() {
    return (
      !window.sessionStorage ||
      (window.sessionStorage && !window.sessionStorage.getItem(this.storageKey))
    );
  }

  get sharedAnalyticsData() {
    return {
      object: 'custom_report',
      custom_report_id: this.args.report.id,
      custom_report_name: this.args.report.title || 'untitled',
    };
  }

  @action
  trackAnalyticsEvent(data) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.sharedAnalyticsData,
      ...data,
    });
  }

  @task({ drop: true }) *saveReport() {
    yield this.withReportAccess(async () => {
      let report = await this.performSaveReport.perform();
      this.notificationsService.notifyConfirmation('Your report has been saved');
      if (this.args.onReportSaved) {
        this.args.onReportSaved(report);
      }
    });
  }

  @task({ drop: true }) *performSaveReport() {
    let isNew = this.args.report.isNew;
    let editedAttributesData = this.editedReportAnalyticsData(this.args.report);
    let isIntercomOwnedReport = this.args.report.isIntercomOwnedReport;
    let report;
    let templatedCharts = this.args.report.availableCharts.filter(
      (chart) => chart.hasDirtyAttributes && isPresent(chart.templateId),
    );

    if (this.appService.app.canSeeR2Beta) {
      report = yield this.args.report.save({
        adapterOptions: {
          canSeeR2Beta: this.appService.app.canSeeR2Beta,
        },
      });
      this.trackAnalyticsEvent({
        action: 'edited_report',
        object: 'custom_report_2',
      });
      templatedCharts.forEach((tc) =>
        this.trackAnalyticsEvent({
          action: 'added_templated_chart_on_report',
          object: 'cr2_custom_chart',
          template_id: tc.templateId,
          custom_chart_id: tc.id,
        }),
      );
    } else {
      report = yield this.args.report.save();
      this.trackAnalyticsEvent({
        action: 'edited',
        object: 'custom_report',
        from_template: isIntercomOwnedReport,
        ...editedAttributesData,
      });
      if (report.availableCharts.length > 0) {
        this.intercomEventService.trackEvent('saved-custom-report');
      }
    }

    if (isNew) {
      this.router.transitionTo('apps.app.reports.custom-reports.report.show', report.id);
    }
    return report;
  }

  @action
  async withReportAccess(action) {
    try {
      return await action();
    } catch (e) {
      if (e.jqXHR.status === 403) {
        this.reportAccessService.loadReportAccessModal(this.args.report.id, () => {
          this.args.report.rollbackAttributes();
          if (isEmpty(this.reportAccessService.currentAdminReportAccess(this.args.report.id))) {
            this.router.transitionTo('apps.app.reports.overview');
          }
        });
      } else {
        captureException(e);
        console.error(e);
        this.notificationsService.notifyError('Something went wrong, try reloading the page.');
        return undefined;
      }
    }
  }

  @task({ restartable: true }) *saveReorderedReport() {
    if (this.appService.app.canSeeR2Beta) {
      this.trackAnalyticsEvent({
        action: 'reordered_report',
        object: 'custom_report_2',
      });
    } else {
      let isIntercomOwnedReport = this.args.report.isIntercomOwnedReport;
      yield this.args.report.saveWithoutRecreatingStoreModels();
      this.notificationsService.notifyConfirmation('Your report has been saved');
      this.trackAnalyticsEvent({
        action: 'reordered',
        ...this.sharedAnalyticsData,
        from_template: isIntercomOwnedReport,
        number_of_charts: this.args.report.charts.length,
      });
      this.intercomEventService.trackEvent('saved-custom-report');
    }
  }

  @action
  cancelChanges() {
    this.args.revert();
    this.notificationsService.notifyConfirmation(
      this.intl.t('reporting.custom-reports.report.changes-discarded'),
    );
  }

  @action
  editChart(chartId) {
    this.trackAnalyticsEvent({
      action: 'clicked',
      object: 'edit_chart',
    });
    this.router.transitionTo('apps.app.reports.custom-reports.report.show.chart.edit', chartId, {
      queryParams: {
        cr2AddingChartToReport: this.appService.app.canSeeR2Beta,
      },
    });
  }

  @task({ drop: true }) *deleteChart(chartId) {
    if (this.customReportsService.canDeleteReport) {
      if (this.appService.app.canSeeR2Beta) {
        let chart = this.store.peekRecord('reporting/custom/chart', chartId);
        this.args.report.removeChart(chart, { save: false });
        this.trackAnalyticsEvent({
          action: 'deleted_report',
          object: 'custom_report_2',
        });
        this.notificationsService.notifyConfirmation(
          this.intl.t('reporting.custom-reports.report.removed-chart'),
        );
      } else {
        let confirm = yield this.intercomConfirmService.confirm({
          title: this.intl.t(
            'reporting.custom-reports.report.delete-chart-confirmation-modal.title',
          ),
          body: this.intl.t('reporting.custom-reports.report.delete-chart-confirmation-modal.body'),
          primaryButtonType: 'primary-destructive',
          confirmButtonText: this.intl.t(
            'reporting.custom-reports.report.delete-chart-confirmation-modal.confirm-text',
          ),
        });
        if (!confirm) {
          return;
        }
        let chart = this.store.peekRecord('reporting/custom/chart', chartId);
        yield this.args.report.removeChart(chart, { save: true });
        this.trackAnalyticsEvent({
          action: 'deleted',
          custom_chart_name: chart.title || 'untitled',
          custom_chart_id: chartId,
        });
        this.notificationsService.notifyConfirmation('Your chart has been deleted');
      }
    } else {
      this.customReportsService.loadDeletePermissionsModal();
    }
  }

  applyDateRangeFilter(filterValues) {
    if (this.shouldDisplayDateFilterWarning) {
      this.notificationsService.notifyWarning(
        'We’ve updated all of the charts in your report to use the date range you selected.',
      );
      if (window.sessionStorage) {
        try {
          window.sessionStorage.setItem(this.storageKey, 'true');
        } catch {} // eslint-disable-line no-empty
      }
    }
    this.args.report.applyDateRange(filterValues);
  }

  @action
  updateFilter(index, attribute, filterValues, operator) {
    this.trackAnalyticsEvent({
      action: 'filtered',
      filter_name: attribute.id,
    });
    this.intercomEventService.trackEvent('filtered-custom-report');

    if (attribute.id === 'time') {
      this.applyDateRangeFilter(filterValues);
      return;
    }

    this.args.report.applyFilter(index, attribute, filterValues, operator);
  }

  @action
  clearFilters() {
    this.trackAnalyticsEvent({
      action: 'clear_filters',
    });
    this.args.report.dateRange = null;
    this.args.report.filters = {};
  }

  @action
  applyLogicalFilterOperator(operator) {
    this.args.report.applyLogicalFilterOperator(operator);
  }

  @action
  addChart(buttonPosition) {
    this.trackAnalyticsEvent({
      action: 'clicked',
      object: 'add_chart',
      button_position: buttonPosition,
    });
    this.intercomEventService.trackEvent('created-custom-report-chart');
    this.transitionToNewChartPage();
  }

  @task({ drop: true }) *duplicateChart(params) {
    if (this.customReportsService.canChangeCustomReports) {
      let { chartId, description } = params;
      let duplicatedChart = yield this.postDuplicateChart.perform(chartId, description);
      if (isEmpty(duplicatedChart)) {
        return;
      }
      this.trackAnalyticsEvent({
        action: 'duplicated',
        object: 'custom_chart',
      });
      this.notificationsService.notifyConfirmation('Your chart was successfully duplicated');
      this.scrollToChart(duplicatedChart.id);
    } else {
      this.customReportsService.loadChangePermissionModal();
    }
  }

  @task({ drop: true }) *postDuplicateChart(chartId, renderableChartDescription) {
    let result;
    let sourceChart = yield this.store.peekRecord('reporting/custom/chart', chartId);
    if (this.appService.app.canSeeR2Beta) {
      let newChart = yield this.args.report.duplicateChart(sourceChart);
      if (isEmpty(newChart.templateId)) {
        newChart.set(
          'title',
          `${this.intl.t('reporting.custom-reports.report.copy-of')} ${sourceChart.title || renderableChartDescription}`,
        );
      }
      result = newChart;
    } else {
      try {
        let duplicatedChart = yield sourceChart.duplicateChart({
          app_id: this.appService.app.id,
          admin_id: this.appService.app.currentAdmin.id,
          id: chartId,
          ...(isEmpty(sourceChart.title) && {
            default_title: renderableChartDescription,
          }),
        });
        result = yield this.store.find('reporting/custom/chart', duplicatedChart.id);
        yield this.args.report.saveChart(result);
      } catch (_e) {
        this.notificationsService.notifyError('Something went wrong, try reloading the page.');
      }
    }
    return result;
  }

  scrollToChart(chartId) {
    let selector = `[draggable-chart-card-id="${chartId}"]`;
    let chartElement = document.querySelector(selector);
    if (!chartElement) {
      return;
    }
    chartElement.scrollIntoView();
  }

  @action
  reorderCharts(source, destination) {
    let reportCharts = this.args.report.charts;
    if (!reportCharts.isFulfilled) {
      throw new Error('report.charts promise is not fulfilled');
    }
    if (reportCharts.isRejected) {
      throw new Error('report.charts promise was rejected');
    }
    let charts = reportCharts.toArray();
    let deleted = charts.splice(source, 1)[0];
    charts.insertAt(destination, deleted);
    reportCharts.setObjects(charts);
  }

  async transitionToNewChartPage() {
    if (this.args.report.isNew && !this.appService.app.canSeeR2Beta) {
      this.router.transitionTo('apps.app.reports.custom-reports.report.new.chart.new', {
        queryParams: {
          source: 'custom_report',
          cr2AddingChartToReport: this.appService.app.canSeeR2Beta,
        },
      });
    } else {
      this.router.transitionTo(
        'apps.app.reports.custom-reports.report.show.chart.new',
        this.args.report.id,
        {
          queryParams: {
            source: 'custom_report',
            cr2AddingChartToReport: this.appService.app.canSeeR2Beta,
          },
        },
      );
    }
  }

  @action
  openSubscriptionModal() {
    this.showSubscriptionModal = true;
  }

  @action
  closeSubscriptionModal() {
    this.showSubscriptionModal = false;
  }

  @action
  openChartTemplateSidePanel() {
    this.trackAnalyticsEvent({
      action: 'opened',
      object: 'chart_template_side_panel',
    });
    this.args.reportMode.isSidePanelOpen = true;
  }

  @action
  closeChartTemplateSidePanel() {
    this.trackAnalyticsEvent({
      action: 'closed',
      object: 'chart_template_side_panel',
    });
    this.args.reportMode.isSidePanelOpen = false;
  }

  get knownValuesEndpointSource() {
    let sourcesMap = this.args.report.charts?.map((chartState) => {
      let metrics = chartState.chartSeries.map((series) => series.metric);
      return metrics.map((metric) => metric.firstSource);
    });
    let sources = new Set(sourcesMap.flat());
    return [...sources].compact();
  }

  get shouldRenderTimezones() {
    let appTimezone = this.timezoneList.find((tz) => tz.value === this.appService.app.timezone);
    return isPresent(appTimezone);
  }

  get timezones() {
    return this.timezoneList.map((tz) => {
      return {
        text: tz.description,
        value: tz.description,
      };
    });
  }

  get defaultTimezoneValue() {
    return this.reportState.timezone;
  }

  get defaultTimezoneName() {
    return this.getTimezoneName(this.defaultTimezoneValue);
  }

  @action
  updateTimezone(name) {
    let value = this.getTimezoneValue(name);
    this.args.report.applyTimeZone(value);
    this.timezoneSelected = name;
  }

  getTimezoneName(value) {
    return this.timezoneList.find((tz) => tz.value === value)?.description;
  }

  getTimezoneValue(name) {
    return this.timezoneList.find((tz) => tz.description === name)?.value;
  }

  get currentTimezoneValue() {
    return this.getTimezoneValue(this.timezoneSelected);
  }

  @task({ drop: true })
  *duplicateReport() {
    let { report, onReportSaved } = this.args;
    if (report.isNew) {
      return;
    }

    return yield this.withReportAccess(async () => {
      if (report.hasDirtyAttributes) {
        let confirmed = await this.intercomConfirmService.confirm(CONFIRMATION_MODAL_CONTENT);
        if (confirmed === true) {
          await this.performSaveReport.perform(report);
        } else {
          report.rollbackAttributes();
        }
      }
      //this returns json object of report or empty object comment here https://github.com/intercom/embercom/pull/49596#discussion_r627234404
      let duplicatedReport = await this.postDuplicateReport.perform(report);

      if (isEmpty(duplicatedReport)) {
        return;
      }
      this.trackAnalyticsEvent({
        action: 'duplicated',
        object: 'custom_report',
      });

      if ('id' in duplicatedReport) {
        this.router.transitionTo(
          'apps.app.reports.custom-reports.report.show',
          duplicatedReport.id,
        );
      }
      // @ts-ignore
      this.notificationsService.notifyConfirmation(
        'Your custom report was successfully duplicated',
      );
      if (onReportSaved) {
        onReportSaved(duplicatedReport);
      }
    });
  }

  @task({ drop: true })
  *postDuplicateReport(report) {
    try {
      let result = yield report.duplicateReport({
        app_id: this.appService.app.id,
        admin_id: this.appService.app.currentAdmin.id,
        id: report.id,
      });
      return result;
    } catch (_e) {
      //TODO: localize string
      this.notificationsService.notifyError('Something went wrong, try reloading the page.');
      return undefined;
    }
  }

  editedReportAnalyticsData(report) {
    let reportChangedAttributes = report.changedAttributes();
    return {
      title_changed: 'title' in reportChangedAttributes,
      description_changed: 'description' in reportChangedAttributes,
      filters_changed: 'filters' in reportChangedAttributes,
      number_of_charts: report.charts.length,
      timezone_changed: 'timezone' in reportChangedAttributes,
      timezone_changed_from_default: this.isTimezoneUpdatedFromDefault(report),
    };
  }

  isTimezoneUpdatedFromDefault(report) {
    let timezoneChanges = report.changedAttributes()['timezone'];
    if (isEmpty(timezoneChanges) || !timezoneChanges) {
      return false;
    }
    let [previousTimeZone] = timezoneChanges;
    let defaultTimeZone = this.appService.app.timezone;
    return !isPresent(previousTimeZone) || previousTimeZone === defaultTimeZone;
  }

  @task({ drop: true })
  *deleteReport() {
    let { report } = this.args;

    let confirm = yield this.intercomConfirmService.confirm({
      title: this.intl.t('reporting.custom-reports.report.delete-confirmation-modal.title'),
      body: this.intl.t('reporting.custom-reports.report.delete-confirmation-modal.body'),
      primaryButtonType: 'primary-destructive',
      confirmButtonText: this.intl.t(
        'reporting.custom-reports.report.delete-confirmation-modal.confirm-text',
      ),
    });

    if (!confirm) {
      return;
    }
    this.trackAnalyticsEvent({
      action: 'deleted',
      object: 'custom_report',
    });

    try {
      yield report.destroyRecord();
      this.notificationsService.notifyConfirmation('Your report has been deleted');

      if (this.favoritesService.isFavoriteReport(report.id)) {
        yield this.favoritesService.removeFromFavorites.perform(report.id);
      }

      if (this.appService.app.canSeeR2Beta) {
        this.router.transitionTo('apps.app.reports.overview');
      } else {
        this.router.transitionTo('apps.app.reports.views.view', 'all-reports');
      }
    } catch (e) {
      if (e.jqXHR.status === 403) {
        this.reportAccessService.loadReportAccessModal(report.id, () => {
          if (isEmpty(this.reportAccessService.currentAdminReportAccess(report.id))) {
            //owners don't have report access. But they won't get 403. If user access has been revoked we want to transitionTo
            report.rollbackAttributes();
            this.router.transitionTo('apps.app.reports.overview');
          }
        });
      } else {
        captureException(e);
        console.error(e);
      }
    }
  }

  get emptyReport() {
    return !this.args.report.hasCharts && this.appService.app.canSeeR2Beta;
  }

  get restrictedViewAccess() {
    if (this.appService.app.canShareReportsInternally) {
      return this.reportAccessService.adminHasRestrictedAccess(this.args.report);
    } else {
      return false;
    }
  }

  get canEditReportOrReportIsNew() {
    return this.reportAccessService.canEditReportOrReportIsNew(this.args.report);
  }

  get showDeprecatedMetricWarning() {
    let charts = this.args.report.charts;
    return (
      this.appService.app.hasTicketsReportingMetricsUpdatedBanner &&
      charts.any((chart) => chart.hasDeprecatedMetrics && chart.hasTicketMetrics)
    );
  }

  // Show metrics updated banner
  // 1. The app has the tickets reporting metrics updated feature enabled
  // 2. There are no deprecated metrics
  // 3. There is at least one chart with ticket metrics
  get showUpdatedMetricBanner() {
    let charts = this.args.report.charts;
    return (
      this.appService.app.hasTicketsReportingUpdatedMetrics &&
      charts.any((chart) => {
        return !chart.hasDeprecatedMetrics && chart.hasTicketMetrics;
      })
    );
  }
}
