/* import __COLOCATED_TEMPLATE__ from './prompt-response-pair.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import {
  type FinSource,
  InboxAssistantConversationPart,
} from 'embercom/objects/inbox/inbox-assistant-conversation';
import { EntityType } from 'embercom/models/data/entity-types';
import {
  normalizeFinSource,
  type NormalizedFinSource,
  CopilotSourceSection,
} from 'embercom/lib/inbox2/copilot/types';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { captureException } from 'embercom/lib/sentry';
import type Session from 'embercom/services/session';

interface Args {
  promptResponsePair: InboxAssistantConversationPart[];
}

export default class PromptResponsePair extends Component<Args> {
  readonly CopilotSourcePreview = CopilotSourceSection.SourcePreview;

  @service declare router: RouterService;
  @service declare session: Session;

  get prompt() {
    return this.args.promptResponsePair[0];
  }

  get response() {
    if (this.args.promptResponsePair.length < 2) {
      return InboxAssistantConversationPart.missingResponsePartPlaceholder();
    }

    return this.args.promptResponsePair[1];
  }

  get sources(): NormalizedFinSource[] {
    return this.normalizeSources(this.response.sources);
  }

  get relatedContent(): NormalizedFinSource[] {
    return this.normalizeSources(this.response.relatedContent);
  }

  @action
  onSourceClicked(_e: PointerEvent) {}

  private normalizeSources(sources: FinSource[] | undefined): NormalizedFinSource[] {
    if (!sources) {
      return [];
    }

    return sources.map((source) => {
      let normalizedSource = normalizeFinSource(source, this.session.workspace.id, this.router);
      return {
        ...normalizedSource,
        onClickLink: () => {
          this.openSourceInNewTab(source);
        },
        instrumentHover: () => {},
        instrumentCopy: () => {},
        instrumentClick: () => {},
      } as NormalizedFinSource;
    });
  }

  private openSourceInNewTab(source: FinSource) {
    let url;
    switch (source.entity_type) {
      case EntityType.ArticleContent: {
        if (source.data.article_id) {
          url = this.router.urlFor(
            'apps.app.articles.articles.article.show',
            source.data.article_id,
          );
        }
        break;
      }
      case EntityType.InternalArticle: {
        url = this.router.urlFor(
          'apps.app.knowledge-hub.view',
          'internal-article',
          source.entity_id,
        );
        break;
      }
      case EntityType.ContentSnippet: {
        url = this.router.urlFor('apps.app.automation.resolution-bot.fin-content', {
          queryParams: {
            content: 'content_snippet',
            id: source.entity_id,
          },
        });
        break;
      }
      case EntityType.ConversationExcerpt:
      case EntityType.PastConversationSnippet:
        url = this.router.urlFor('apps.app.inbox.conversation', source.data.id);
        break;
      case EntityType.SlackThread:
        url = source.data.source_url;
        break;
      default: {
        url = null;
      }
    }

    if (url) {
      safeWindowOpen(url, '_blank');
    } else {
      captureException(new Error('Could not open source in new tab'), {
        fingerprint: ['copilot-reporting', 'unsopported-source'],
        extra: { source: JSON.stringify(source) },
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::Copilot::PromptResponsePair': typeof PromptResponsePair;
    'reporting/custom/report/copilot/prompt-response-pair': typeof PromptResponsePair;
  }
}
