/* import __COLOCATED_TEMPLATE__ from './descriptor-details-handler.hbs'; */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency-decorators';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import { CONVERSATION_OBJECT_TYPE_NAME } from 'embercom/models/custom-objects/constants/object-types';
import {
  ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP,
  ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP,
} from 'embercom/models/custom-objects/constants/attribute-descriptor-types';
import { REVERSE_CARDINALITY_NAME_MAP } from 'embercom/models/custom-objects/constants/relationship-cardinalities';
import { REFERENCES_ONE, REFERENCES_MANY } from 'embercom/models/objects/constants/reference-types';
import { post } from 'embercom/lib/ajax';
import { TicketCategory } from 'embercom/objects/inbox/ticket';

const LIST_OPTIONS_LIMIT = 250;
const LIST_OPTIONS_INCREASED_LIMIT = 500;
const REQUIRED_ATTRIBUTE_OPTIONS = [
  { text: 'Not required', value: 'not_required' },
  { text: 'Required to create ticket', value: 'required_to_create' },
];

export default class extends Component {
  @service store;
  @service notificationsService;
  @service appService;
  @service intercomEventService;
  @service customObjectsService;
  @service intl;
  @service permissionsService;
  @service session;

  sourceObjectName = CONVERSATION_OBJECT_TYPE_NAME;

  @tracked editMode = isPresent(this.args.attributeToEdit);
  @tracked name = this.editMode ? this.args.attributeToEdit.displayName : '';
  @tracked description = this.editMode ? this.args.attributeToEdit.description : '';
  @tracked dataType = this.editMode
    ? this.args.attributeToEdit.dataType
    : this.typeDropdownItems.firstObject.value;
  @tracked displayableDataType = this.editMode
    ? this.args.attributeToEdit.displayableDataType
    : this.typeDropdownItems.firstObject.text;
  @tracked listOptions = this.editMode ? this.args.attributeToEdit.unArchivedListOptions : [];
  @tracked multiline = this.editMode ? this.args.attributeToEdit.multiline : false;
  @tracked allowMultipleValues = this.editMode
    ? this.args.attributeToEdit.allowMultipleValues
    : false;
  @tracked isRequired = this.editMode ? this.args.attributeToEdit.required : false;
  @tracked isVisibleOnCreate = this.editMode
    ? this.args.attributeToEdit.visibleOnCreate
    : !this.isConversationTicketType;
  @tracked isVisibleToUsers = this.editMode
    ? this.args.attributeToEdit.visibleToUsers
    : !this.isConversationTicketType;
  @tracked isRequiredForUsers = this.editMode
    ? this.args.attributeToEdit.requiredToCreateForUsers
    : false;
  @tracked isRequiredForTeammates = this.editMode
    ? this.args.attributeToEdit.requiredToCreate
    : false;
  @tracked order = this.editMode ? this.args.attributeToEdit.order : 0;
  @tracked visibleToTeamIds = this.editMode
    ? this.args.attributeToEdit.existingAndVisibleTeamIds
    : null;
  @tracked isVisibilityLimited = this.editMode
    ? this.args.attributeToEdit.isVisibilityLimited
    : false;
  @tracked requiredAttributeValue = this.editMode
    ? this.getRequiredAttributeValue(this.args.attributeToEdit)
    : 'not_required';
  @tracked relatedObjectTypeIdentifier;
  @tracked relatedObjectAttributeName;
  @tracked relatedObjectAttributeDescription;
  @tracked relationshipCardinality = this.args.attributeToEdit?.cardinality;
  @tracked showEditCardinalityWarning = false;
  @tracked showEditReferenceTypeWarning = false;
  @tracked referenceType = this.args.attributeToEdit?.reference?.referenceType || REFERENCES_MANY;
  @tracked referencedObjectTypeIdentifier =
    this.args.attributeToEdit?.reference?.referencedObjectTypeIdentifier;
  @tracked isDefaultAttribute = this.args.attributeToEdit?.isBuiltIn || false;
  @tracked isDeletable = !this.isDefaultAttribute;
  @tracked isEditable = !this.isDefaultAttribute;
  @tracked isClassificationAttribute =
    this.args.attributeToEdit?.isClassificationAttribute || false;

  @tracked userConversationAttachmentsEnabled;

  constructor() {
    super(...arguments);
    if (this.args.descriptorTemplateParams) {
      this.name = this.args.descriptorTemplateParams.name;
      this.description = this.args.descriptorTemplateParams.description;
      this.dataType = this.args.descriptorTemplateParams.dataType;
      this.listOptions = this.args.descriptorTemplateParams.listOptionLabels.map((label) =>
        this.createListOptionObject(label),
      );
    }

    if (this.isFilesType) {
      this.isRequiredForUsers = false;
    }

    if (this.isInternalTicketType) {
      this.isVisibleToUsers = false;
      this.isRequiredForUsers = false;
    }

    if (this.isFilesType) {
      this.setUserConversationAttachmentsEnabled();
    }
  }

  setUserConversationAttachmentsEnabled() {
    if (this.permissionsService.currentAdminCan('can_access_workspace_settings')) {
      // eslint-disable-next-line promise/prefer-await-to-then
      this.store.findRecord('attachment-settings', this.appService.app.id).then((settings) => {
        this.userConversationAttachmentsEnabled =
          settings.userConversationMediaEnabled || settings.userConversationFilesEnabled;
      });
    } else {
      //If user does not have access security settings default to true.
      this.userConversationAttachmentsEnabled = true;
    }
  }

  get typeDropdownItems() {
    if (this.args.availableTypes) {
      return this.args.availableTypes;
    }

    let options = [
      { text: 'Text', value: 'string', icon: ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP['string'] },
      { text: 'List', value: 'list', icon: ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP['list'] },
      { text: 'Number', value: 'integer', icon: ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP['integer'] },
      {
        text: 'Decimal Number',
        value: 'decimal',
        icon: ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP['decimal'],
      },
      { text: 'Boolean', value: 'boolean', icon: ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP['boolean'] },
      {
        text: 'Date & Time',
        value: 'datetime',
        icon: ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP['datetime'],
      },
    ];

    if (this.isConversationTicketType) {
      options.push({
        text: 'Relationship',
        value: 'relationship',
        icon: ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP['relationship'],
      });
    }

    if (!this.isConversationTicketType) {
      options.push({
        text: 'File upload',
        value: 'files',
        icon: 'attachment',
      });
    }

    return options;
  }

  @action
  onReferenceTypeToggle() {
    let existingReferenceType = this.args.attributeToEdit?.reference?.referenceType;

    this.referenceType = this.referenceType === REFERENCES_MANY ? REFERENCES_ONE : REFERENCES_MANY;
    this.showEditReferenceTypeWarning =
      isPresent(existingReferenceType) && this.referenceType !== existingReferenceType;
  }

  get isStringType() {
    return this.dataType === 'string';
  }

  get isListType() {
    return this.dataType === 'list';
  }

  get isFilesType() {
    return this.dataType === 'files';
  }

  get isInternalTicketType() {
    if (this.appService.app.canUseTicketsModelV3) {
      switch (this.args.ticketType?.category) {
        case TicketCategory.Task:
          return this.args.ticketType?.internal;
        case TicketCategory.Tracker:
          return true;
        default:
          return false;
      }
    } else {
      return this.args.ticketType?.internal;
    }
  }

  get showAttachmentsDisabledHint() {
    return this.isFilesType && !this.userConversationAttachmentsEnabled;
  }

  get customerSharingHint() {
    if (this.showAttachmentsDisabledHint) {
      return this.intl.t(
        'settings.conversation-attributes.file-attribute-attachments-disabled-hint',
        {
          htmlSafe: true,
        },
      );
    }
    return null;
  }

  get filesRequiredTooltip() {
    return this.isFilesType
      ? this.intl.t('settings.conversation-attributes.file-attribute-required')
      : null;
  }

  get optionsLimit() {
    return this.appService.app.canUseIncreasedListOptionsLimit
      ? LIST_OPTIONS_INCREASED_LIMIT
      : LIST_OPTIONS_LIMIT;
  }

  get optionLimitReached() {
    return this.listOptions.length >= this.optionsLimit;
  }

  get attributeIsArchived() {
    return this.args.attributeToEdit?.archived;
  }

  get canArchiveAttribute() {
    return this.editMode && !this.attributeIsArchived && this.isDeletable;
  }

  get requiredAttributeOptions() {
    return REQUIRED_ATTRIBUTE_OPTIONS;
  }

  createListOptionObject(label = '') {
    return this.store.createRecord('conversation-attributes/list-option', { label });
  }

  get isValid() {
    let isVisibilityValid =
      !this.isVisibilityLimited || (this.visibleToTeamIds && this.visibleToTeamIds.length !== 0);
    return this.name.trim() !== '' && isVisibilityValid;
  }

  get isConversationTicketType() {
    if (this.args.ticketType === undefined) {
      return true;
    }

    return this.args.ticketType.isConversationTicketType;
  }

  get isTextMultiline() {
    return this.dataType === 'string' && this.multiline;
  }

  get namePlaceholder() {
    return this.isConversationTicketType
      ? 'e.g. Type'
      : 'The name of the piece of information that users will be asked to enter';
  }

  get descriptionValue() {
    if (this.appService.app.canEditTicketDesciptionDescriptions) {
      return this.description;
    }
    return this.isDefaultAttribute ? this.args.attributeToEdit?.description : this.description;
  }

  get descriptionPlaceholder() {
    return this.isConversationTicketType
      ? 'e.g. Type of conversation'
      : 'A description of what this information is for or why users should enter it';
  }

  get attributeLimitWarning() {
    let attributeType = this.isConversationTicketType ? 'conversation' : 'ticket';
    return `You have reached the limit of ${this.optionsLimit} options for this ${attributeType} attribute.`;
  }

  get requiredAttributeText() {
    let attributeType = this.isConversationTicketType ? 'conversation' : 'ticket';
    let action = this.isConversationTicketType ? 'closing' : 'creating';

    return `A teammate will need to complete this before ${action} a ${attributeType}`;
  }

  get requiredToCreate() {
    if (this.isConversationTicketType) {
      return false;
    }

    return this.isRequiredForTeammates;
  }

  get requiredToClose() {
    return this.isConversationTicketType
      ? this.isRequired
      : this.requiredAttributeValue !== 'not_required';
  }

  getRequiredAttributeValue(attribute) {
    if (attribute.requiredToCreate) {
      return 'required_to_create';
    } else {
      return 'not_required';
    }
  }

  @action
  addListOption() {
    let newListOption = this.createListOptionObject();
    this.listOptions.pushObject(newListOption);
  }

  @action
  removeListOption(index) {
    if (this.listOptions.length > 2) {
      this.listOptions.removeAt(index);
    }
  }

  @action
  updateType(type) {
    this.listOptions = [];
    if (type === 'list') {
      if (this.listOptions.length === 0) {
        this.addListOption();
        this.addListOption();
      }
    }

    if (type === 'files') {
      this.isVisibleToUsers = false;
      this.isRequiredForUsers = false;
      this.setUserConversationAttachmentsEnabled();
    }
    this.dataType = type;
  }

  @action
  saveAttribute() {
    if (!this.listOptions.every((option) => option.label.length > 0)) {
      this.notificationsService.notifyError(
        'Failed to update conversation attribute, all list options must have values',
      );
      return;
    }

    if (this.editMode) {
      this.updateAttribute.perform();
    } else {
      this.createAttribute.perform();
    }
  }

  @action
  rollbackAttributeAndCloseModal() {
    this.args.onModalClose();
    this.args.attributeToEdit?.rollbackAttributes();
    if (this.args.attributeToEdit?.listOptions) {
      this.args.attributeToEdit?.listOptions.forEach((option) => option?.rollbackAttributes());
    }
  }

  @action
  toggleIsRequired() {
    this.isRequired = !this.isRequired;
  }

  @action
  toggleVisibleOnCreate() {
    this.isVisibleOnCreate = !this.isVisibleOnCreate;
  }

  @action
  toggleVisibleToUsers() {
    this.isVisibleToUsers = !this.isVisibleToUsers;
  }

  @action
  onVisibleToUsersChange() {
    if (this.isVisibleToUsers && this.isRequiredForUsers) {
      this.isRequiredForUsers = false;
    }
  }

  @action
  onVisibleToTeammatesChange() {
    if (this.isVisibleOnCreate && this.isRequiredForTeammates) {
      this.isRequiredForTeammates = false;
    }
  }

  @action
  updateVisibility(teamIds, visibility) {
    this.visibleToTeamIds = teamIds;
    this.isVisibilityLimited = visibility;
  }

  @action
  toggleMultiline() {
    this.multiline = !this.multiline;
  }

  @action
  toggleAllowMultipleValues() {
    this.allowMultipleValues = !this.allowMultipleValues;
  }

  @action
  requiredAttributeSelected(newValue) {
    this.requiredAttributeValue = newValue;

    if (this.requiredToCreate) {
      this.isVisibleOnCreate = true;
      this.isVisibleToUsers = true;
    }
  }

  @action
  reorderOptions(reordered) {
    this.listOptions = reordered;
  }

  @action
  listOptionUsedInConditions(index) {
    if (!this.args.canEditConditions) {
      return false;
    }

    let listOptionId = this.listOptions[index].id;
    let allConditions = this.store.peekAll('conversation-attributes/condition');
    return allConditions.any((condition) => condition.controllingListOptionId === listOptionId);
  }

  @task({ drop: true })
  *updateAttribute() {
    let conversationAttributeDescriptor = this.args.attributeToEdit;
    let relationship = this.args.attributeToEdit?.relationship;
    let reference = this.args.attributeToEdit?.reference;

    try {
      if (relationship) {
        if (this.args.attributeToEdit?.isDestinationRelationshipAttribute) {
          this.relationshipCardinality = REVERSE_CARDINALITY_NAME_MAP[this.relationshipCardinality];
        }
        relationship.setProperties({
          cardinality: this.relationshipCardinality,
        });
      }

      if (reference) {
        reference.setProperties({
          referenceType: this.referenceType,
        });
      }

      conversationAttributeDescriptor.setProperties({
        name: this.isDefaultAttribute ? this.args.attributeToEdit.name : this.name,
        description: this.descriptionValue,
        listOptions: this.listOptions,
        multiline: this.multiline,
        allowMultipleValues: this.allowMultipleValues,
        required: this.requiredToClose,
        requiredToCreate: this.requiredToCreate,
        requiredToCreateForUsers: this.isRequiredForUsers,
        order: this.order,
        visibleToTeamIds: this.isVisibilityLimited ? this.visibleToTeamIds : null,
        visibleOnCreate: this.isVisibleOnCreate,
        visibleToUsers: this.isVisibleToUsers,
        relatedObjectAttributeName: this.relatedObjectAttributeName,
        relatedObjectAttributeDescription: this.relatedObjectAttributeDescription,
        relationship: this.args.attributeToEdit.relationship,
        reference: this.args.attributeToEdit.reference,
        isClassificationAttribute: this.args.attributeToEdit.isClassificationAttribute,
      });

      yield conversationAttributeDescriptor.save();
      this.trackEvent('updated');
      this.notificationsService.notifyConfirmation(
        'Your conversation attribute was successfully updated.',
      );
      this.args.onModalClose();
    } catch (error) {
      conversationAttributeDescriptor.rollbackAttributes();
      if (error.jqXHR.status === 422) {
        if (error.jqXHR.responseJSON?.errors) {
          this.notificationsService.notifyError(error.jqXHR.responseJSON.errors[0].message);
        } else {
          this.notificationsService.notifyError(
            'Failed to update ticket attribute as name is duplicate of another attribute.',
          );
        }
      } else if (error.jqXHR.status === 400) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.errors);
      } else {
        this.notificationsService.notifyError(
          'Failed to update conversation attribute, please try again.',
        );
      }
    }
  }

  @task({ drop: true })
  *createAttribute() {
    let ticketTypeId = this.isConversationTicketType ? null : this.args.ticketType.id;
    let descriptorsScopedByTicketType =
      ConversationAttributeDescriptor.peekAllAndMaybeLoad().filter(
        (d) => d.ticketTypeId === ticketTypeId,
      );

    let maxOrder = 0;
    if (descriptorsScopedByTicketType.length !== 0) {
      maxOrder = Math.max(...descriptorsScopedByTicketType.map((d) => d.order)) + 1;
    }

    let relationship;
    let reference;

    if (this.dataType === ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP) {
      reference = this.store.createRecord('objects/reference', {
        referencedObjectTypeIdentifier: this.referencedObjectTypeIdentifier,
        referenceType: this.referenceType,
      });
    }

    let conversationAttributeDescriptor = this.store.createRecord(
      'conversation-attributes/descriptor',
      {
        name: this.name,
        description: this.description,
        dataType: this.dataType,
        listOptions: this.listOptions,
        multiline: this.multiline,
        allowMultipleValues: this.allowMultipleValues,
        required: this.requiredToClose,
        requiredToCreate: this.requiredToCreate,
        requiredToCreateForUsers: this.isRequiredForUsers,
        order: maxOrder,
        visibleToTeamIds: this.isVisibilityLimited ? this.visibleToTeamIds : null,
        ticketTypeId,
        visibleOnCreate: this.isVisibleOnCreate,
        visibleToUsers: this.isVisibleToUsers,
        relatedObjectAttributeName: this.relatedObjectAttributeName,
        relatedObjectAttributeDescription: this.relatedObjectAttributeDescription,
        isClassificationAttribute: this.isClassificationAttribute,
        relationship,
        reference,
      },
    );

    try {
      yield conversationAttributeDescriptor.save();
      yield conversationAttributeDescriptor.relatedAttribute; // Cache related attribute;
      this.trackEvent('created');
      this.notificationsService.notifyConfirmation(
        'Your conversation attribute was successfully created.',
      );
      this.args.onModalClose();
      if (this.args.onDescriptorCreated) {
        this.args.onDescriptorCreated(conversationAttributeDescriptor);
      }
    } catch (error) {
      this.store.unloadRecord(conversationAttributeDescriptor);
      if (error.jqXHR.status === 422) {
        if (error.jqXHR.responseJSON?.errors) {
          this.notificationsService.notifyError(error.jqXHR.responseJSON.errors[0].message);
        } else {
          this.notificationsService.notifyError(
            'Failed to create ticket attribute as name is duplicate of another attribute.',
          );
        }
      } else if (error.jqXHR.status === 400) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.errors);
      } else {
        this.notificationsService.notifyError(
          'Failed to create conversation attribute, please try again.',
        );
      }
    }
  }

  @task *valueToDescriptionTask() {
    yield this.migrateAttributeValueToTickeDefaultDescription();
  }

  async migrateAttributeValueToTickeDefaultDescription() {
    let ticketTypeId = this.args.ticketType.id;
    await post(`/ember/inbox/ticket_types/${ticketTypeId}/migrate_attribute_to_description`, {
      app_id: this.appService.app.id,
      descriptor_id: this.args.attributeToEdit.id,
    });
    this.args.onModalClose();
    this.notificationsService.notifyConfirmation(
      this.intl.t('settings.ticket-data.migrate-field.confirmation'),
    );
  }

  trackEvent(action) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'descriptor',
      place: 'settings',
      required: this.isRequired,
      name: this.name,
      description: this.description,
      listOptions: this.listOptions,
      multiline: this.multiline,
      allowMultipleValues: this.allowMultipleValues,
      order: this.order,
      visibleToTeamIds: this.visibleToTeamIds,
    });
  }
}
