/* import __COLOCATED_TEMPLATE__ from './iframe.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */

import ENV from 'embercom/config/environment';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
// @ts-ignore
import { globalRef } from 'ember-ref-bucket';
import type RouterService from '@ember/routing/router-service';
import { tracked } from 'tracked-built-ins';
import { action } from '@ember/object';
import { registerDestructor } from '@ember/destroyable';
import { next } from '@ember/runloop';
import type RouteInfo from '@ember/routing/route-info';
import type ReactService from 'embercom/services/react';
import { getOwner } from '@ember/application';

const BOOT_TIMEOUT = 20000;

const isReactRoute = (route: RouteInfo) => {
  let metadata = route?.metadata;
  return metadata && typeof metadata === 'object' && 'react' in metadata && metadata.react;
};

export default class ReactIframe extends Component {
  @tracked isBooted = false;
  @tracked isIframeVisible = false;

  @service declare router: RouterService;
  @service declare react: ReactService;

  //services used in the messages received from the iframe
  @service navbarCollapsingService: $TSFixMe;
  @service notificationsService: $TSFixMe;

  @globalRef('react-iframe') declare iframe: HTMLIFrameElement;
  @globalRef('react-frame-container') declare frameContainer: HTMLElement;
  @globalRef('main-content-well') declare mainContentWell: HTMLElement;

  @action
  showIframe() {
    this.isIframeVisible = true;
    this.mainContentWell.style.display = 'none';
    this.frameContainer.style.minWidth = '100%';
    this.frameContainer.style.minHeight = '100%';
  }

  @action
  hideIframe() {
    this.isIframeVisible = false;
    this.mainContentWell.style.display = 'block';
    this.frameContainer.style.minWidth = '0';
    this.frameContainer.style.minHeight = '0';
  }

  get isTesting() {
    return ENV.environment === 'test';
  }

  get showLoadingIndicator() {
    return this.isIframeVisible && !this.isBooted && !this.react.didBootFail;
  }

  postBridgeMessage(type: string, payload: any) {
    if (!this.isIframeVisible) {
      return;
    }

    let contentWindow = this.iframe?.contentWindow;
    contentWindow!.postMessage({ type, payload, bridge: true }, window.location.origin);
  }

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.messageHandler = this.messageHandler.bind(this);

    next(() => {
      if (isReactRoute(this.router.currentRoute)) {
        this.showIframe();
      }
    });

    let bootTimeout = setTimeout(() => {
      if (!this.isBooted) {
        this.hideIframe();
        this.react.setBootFailed();

        // Force current route to refresh and re-render but with React disabled
        // @ts-expect-error
        let currentRouteInstance = getOwner(this).lookup(`route:${this.router.currentRouteName}`);
        currentRouteInstance?.refresh();
      }
    }, BOOT_TIMEOUT);

    registerDestructor(this, () => {
      bootTimeout && clearTimeout(bootTimeout);
    });

    window.addEventListener('message', this.messageHandler);

    registerDestructor(this, () => {
      window.removeEventListener('message', this.messageHandler);
    });

    this.router.on('routeDidChange', (transition) => {
      if (!this.iframe?.contentWindow || !this.mainContentWell || !this.frameContainer) {
        return;
      }

      if (isReactRoute(transition.to)) {
        if (!this.isIframeVisible) {
          this.showIframe();
        }
        this.postBridgeMessage('route:navigation-request', { to: this.router.currentURL });
      } else {
        if (this.isIframeVisible) {
          this.hideIframe();
        }
        this.postBridgeMessage('route:navigation-request', { to: '/empty' });
      }
    });
  }

  messageHandler(event: MessageEvent) {
    if (event.origin !== window.location.origin) {
      return;
    }

    if (event.data.bridge) {
      this.handleBridgeEvent(event);
    }
  }

  handleBridgeEvent(event: MessageEvent) {
    let { type, payload } = event.data || {};
    switch (type) {
      case 'route:boot':
        this.routerBootEventHandler();
        break;
      case 'route:change':
        this.router.transitionTo(payload.pathname);
        break;
      case 'navbar:toggle':
        this.navbarCollapsingService.toggleNavbarCollapsed();
        break;
      case 'notification:default':
        this.notificationsService.notify(payload.message, payload.duration);
        break;
      case 'notification:confirmation':
        this.notificationsService.notifyConfirmation(payload.message, payload.duration);
        break;
      case 'notification:help':
        this.notificationsService.notifyHelp(payload.message, payload.duration);
        break;
      case 'notification:error':
        this.notificationsService.notifyError(payload.message, payload.duration);
        break;
      case 'notification:warning':
        this.notificationsService.notifyWarning(payload.message, payload.duration);
        break;
      case 'notification:loading':
        this.notificationsService.notifyLoading(payload.message, payload.duration);
        break;
    }
  }

  routerBootEventHandler() {
    this.isBooted = true;

    if (isReactRoute(this.router.currentRoute)) {
      this.postBridgeMessage('route:navigation-request', { to: this.router.currentURL });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'React::Iframe': typeof ReactIframe;
    'react/iframe': typeof ReactIframe;
  }
}
