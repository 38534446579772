/* import __COLOCATED_TEMPLATE__ from './copilot-report.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { WIDTH_TYPES, HEIGHT_TYPES } from 'embercom/models/reporting/custom/chart';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type ReportingSettings from 'embercom/models/reporting/settings';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import type RouterService from '@ember/routing/router-service';

const DEFAULT_CHART_DEFINITION = {
  id: null,
  title: null,
  visualization_type: null,
  metric_property: null,
  metric_function: null,
  stacked: false,
  view_by: null,
  view_by_time_interval: null,
  view_by_display_limit: 10000,
  view_by_display_other: false,
  width_type: null,
  height_type: null,
};

interface Args {
  settings: typeof ReportingSettings;
  conversationAttributeDescriptors: ConversationAttributeDescriptor[];
}

export default class CopilotReport extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare router: RouterService;

  get reportDefinition() {
    return {
      id: `copilot_report`,
      title: null,
      description: null,
      charts: this.charts,
      date_range: null,
      filters: {},
    };
  }

  metricName(metricId: string) {
    let metric = this.reportingMetrics.getMetricById(metricId);
    return metric.name;
  }

  metricDescription(metricId: string) {
    let metric = this.reportingMetrics.getMetricById(metricId);
    return sanitizeHtml(`${metric.description}  ${this.intl.t('reporting.copilot.more-details')}`);
  }

  get charts() {
    let teammateSettingsUrl = null;
    if (this.appService.app.canUseFeature('team-activation-channels-new-ia-settings-hub')) {
      teammateSettingsUrl = this.router.urlFor(
        'apps.app.settings.workspace.teammates.index',
        this.appService.app.id,
      );
    } else {
      teammateSettingsUrl = this.router.urlFor(
        'apps.app.settings.teammates',
        this.appService.app.id,
      );
    }
    let copilotCharts: any[] = [
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'copilot_involvement_rate_counter',
        title: this.intl.t('reporting.copilot.copilot-involvement-rate-title'),
        tooltip: this.metricDescription('copilot.conversations_involvement_rate'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.THREE_COLUMN,
        height_type: HEIGHT_TYPES.SMALL,
        chart_series: [
          {
            metric_id: 'copilot.conversations_involvement_rate',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'copilot_teammate_searches_counter',
        title: this.metricName('copilot.teammate_searches'),
        tooltip: this.metricDescription('copilot.teammate_searches'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.THREE_COLUMN,
        height_type: HEIGHT_TYPES.SMALL,
        chart_series: [
          {
            metric_id: 'copilot.teammate_searches',
          },
        ],
        disable_drill_in_popover: true,
        drill_in_icon: 'fin',
        drill_in_label: this.intl.t('reporting.copilot.drill-in-label'),
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'copilot_involved_conversations_counter',
        title: this.metricName('copilot.conversations_involved'),
        tooltip: this.metricDescription('copilot.conversations_involved'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.SMALL,
        chart_series: [
          {
            metric_id: 'copilot.conversations_involved',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'copilot_response_interaction_rate',
        title: this.intl.t('reporting.copilot.interaction-rate-title'),
        tooltip: this.metricDescription('copilot.interaction_rate'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.SMALL,
        chart_series: [
          {
            metric_id: 'copilot.interaction_rate',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'copilot_teammate_using_counter',
        title: this.metricName('copilot.teammate_using'),
        tooltip: this.metricDescription('copilot.teammate_using'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.SMALL,
        chart_series: [
          {
            metric_id: 'copilot.teammate_using',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'copilot_involvement_rate_over_time',
        title: this.intl.t('reporting.copilot.involvement-rate-over-time-title'),
        tooltip: this.intl.t('reporting.copilot.involvement-rate-over-time-description'),
        visualization_type: VISUALIZATION_TYPES.LINE,
        width_type: WIDTH_TYPES.THREE_COLUMN,
        height_type: HEIGHT_TYPES.TALL,
        view_by: 'time',
        chart_series: [
          {
            metric_id: 'copilot.conversations_involvement_rate', // is this the right metric?
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'copilot_teammate_using_over_time',
        title: this.intl.t('reporting.copilot.teammates-using-over-time-title'),
        tooltip: this.intl.t('reporting.copilot.teammates-using-over-time-description'),
        visualization_type: VISUALIZATION_TYPES.LINE,
        width_type: WIDTH_TYPES.THREE_COLUMN,
        height_type: HEIGHT_TYPES.TALL,
        view_by: 'time',
        chart_series: [
          {
            metric_id: 'copilot.teammate_using',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'copilot_teammate_overview_table',
        title: this.intl.t('reporting.copilot.teammate-overview-table-title'),
        tooltip: this.intl.t('reporting.copilot.teammate-overview-table-description', {
          teammateSettingsUrl,
        }),
        visualization_type: VISUALIZATION_TYPES.TABLE,
        view_by: 'teammate',
        width_type: WIDTH_TYPES.FULL,
        should_render_chrome: true,
        show_default_chart_title: false,
        chart_series: [
          {
            metric_id: 'copilot.conversations_involvement_rate',
          },
          {
            metric_id: 'copilot.conversations_involved',
          },
          {
            metric_id: 'copilot.teammate_searches',
          },
          {
            metric_id: 'copilot.interaction_rate',
          },
        ],
      },
    ];

    if (this.appService.app.canUseFeature('group-ps-fin-content-usage')) {
      copilotCharts.push({
        ...DEFAULT_CHART_DEFINITION,
        id: 'fin_copilot_content_usage_table',
        title: this.intl.t('components.reporting.bespoke.fin-content-table.header'),
        tooltip: null,
        visualization_type: VISUALIZATION_TYPES.BESPOKE,
        component_name: 'fin-ai-copilot-content-table',
        view_by: 'content_references',
        width_type: WIDTH_TYPES.FULL,
        height_type: HEIGHT_TYPES.FULL,
        chart_series: [
          {
            metric_id: 'copilot.conversations_involved',
          },
        ],
      });
    }

    return copilotCharts;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Reports::Copilot::CopilotReport': typeof CopilotReport;
  }
}
