/* import __COLOCATED_TEMPLATE__ from './role-card.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Router from '@ember/routing/router-service';

interface Args {
  role: $TSFixMe;
  newSettings?: boolean;
}

export default class RoleCard extends Component<Args> {
  @service declare router: Router;

  get addTeammateRoute() {
    return this.args.newSettings
      ? 'apps.app.settings.workspace.teammates'
      : 'apps.app.settings.teammates';
  }

  get teammatesRoute() {
    return this.args.newSettings
      ? 'apps.app.settings.workspace.teammates'
      : 'apps.app.settings.teammates';
  }

  @action
  goToEditRoles() {
    let editRolesRoute = 'apps.app.settings.workspace.teammates.roles.edit';
    this.router.transitionTo(editRolesRoute, this.args.role.id);
    return;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Teammates::Roles::RoleCard': typeof RoleCard;
    'settings/teammates/roles/role-card': typeof RoleCard;
  }
}
